import {
  faBroom,
  faFileInvoice,
  faFlask,
  faMap,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Typography, useMediaQuery, Box, Tooltip, IconButton, Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, useContext, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { UserContext } from '../../../context';
import { InvalidLocationIcon, Loader, Card } from '../../../components';
import {
  ICalendarView,
  IRouteUpdateMode,
  IService,
  IServiceChange,
  IServiceRoute,
  ITechnician,
  ITechOptimizationEvent,
} from '../../../models';
import { createServiceDraggableId, createTechnicianDroppableId } from '../draggableUtils';
import { isRouteStartOrEnd } from '../utils';
import { SortableItem } from './SortableItem';
import { Autorenew } from '@mui/icons-material';
import { getServiceRouteDetails } from '../../../fetch';

export interface ITechPodProps {
  tech: ITechnician;
  techIndex: number;
  route: IServiceRoute;
  routeIndex?: number;
  saving?: boolean;
  hideEditButton?: boolean;
  condense?: boolean;
  toggleSelection?: (
    e: React.KeyboardEvent,
    podId: string,
    route: IService[],
    tech: ITechnician
  ) => void;
  selectedDraggableIds?: string[];
  activeDraggableId?: string | null;
  onMapClick?: (routeIndex: number) => void;
  updateMode?: IRouteUpdateMode;
  changes?: Record<string, IServiceChange>;
  readonly?: boolean;
  allowMultiDrag?: boolean;
  showServiceIndex?: boolean;
  serviceIndexStyle?: 'avatar' | 'inline';
  allowOptimization?: boolean;
  onOptimizationClick?: (event: ITechOptimizationEvent) => unknown;
  serviceRoutes?: IServiceRoute[];
  setUpdatedRoutes?: (data: IServiceRoute[]) => void;
  view?: ICalendarView;
  serviceDate: string;
}

export const TechPod: FC<ITechPodProps> = ({
  tech,
  techIndex,
  route,
  hideEditButton = false,
  routeIndex = 0,
  saving,
  condense = false,
  toggleSelection,
  selectedDraggableIds,
  activeDraggableId,
  onMapClick,
  updateMode = 'Single',
  changes,
  readonly = false,
  allowMultiDrag = true,
  showServiceIndex = false,
  serviceIndexStyle = 'avatar',
  allowOptimization = true,
  onOptimizationClick,
  serviceRoutes,
  setUpdatedRoutes,
  view = ICalendarView.Day,
  serviceDate,
}) => {
  const isPrint = useMediaQuery('print');
  const isCondensed = isPrint ? true : condense;

  const classes = useStyles({ condense: isCondensed });
  const { isOfficeAdmin } = useContext(UserContext);
  const [isUpdatingService, setIsUpdatingService] = useState(false);

  const handleOptimizationClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tech: ITechnician
  ) => {
    e.stopPropagation();
    if (!allowOptimization || !onOptimizationClick) {
      return;
    }
    onOptimizationClick({ tech, route });
  };

  const handleSiteChange = async (id: string | number) => {
    try {
      setIsUpdatingService(true);
      const res = await getServiceRouteDetails(id);
      if (serviceRoutes) {
        // update the current array index with the new routes data
        serviceRoutes[routeIndex] = res;
        setUpdatedRoutes?.(serviceRoutes);
      }
    } catch (error) {
    } finally {
      setIsUpdatingService(false);
    }
  };

  const totalServiceCount = tech.services.filter(s => !isRouteStartOrEnd(s)).length;
  const techDroppableId = createTechnicianDroppableId({
    routeId: route.routeId,
    userId: tech.userId,
    serviceDate: route.serviceDate,
    routeIndex,
    techIndex,
  });
  const hasInvalidLocations = tech.services.some(
    service => !service?.latitude || !service?.longitude
  );

  const hasUnsortedItems = tech.services.some(service => !service.isSorted);

  return (
    <Card>
      {isUpdatingService && <Loader type="overlay" position="centered" />}
      <div className={classes.header}>
        <div className={classes.techInfoAndMap}>
          <Box display="flex">
            <Avatar
              variant="circular"
              alt={tech.userName}
              sx={{
                width: '30px',
                height: '30px',
                border: `2px solid ${tech?.color ? tech.color : '#aaaaaa'}`,
                backgroundColor: `${tech?.color ? tech.color : '#aaaaaa'}`,
              }}
            >
              {tech.userName.slice(0, 1)}
            </Avatar>
          </Box>
          <Box className={classes.techName}>
            <Typography sx={{ fontSize: '0.85rem' }}>{tech.userName}</Typography>
          </Box>

          {!hideEditButton && (
            <div className={classes.mapSection}>
              <Button
                onClick={() => onMapClick?.(routeIndex as number)}
                className={clsx('print--none', classes.filtersButton)}
                color="primary"
                disabled={saving}
                size="small"
                startIcon={<FontAwesomeIcon icon={faMap} />}
              >
                Map
              </Button>
            </div>
          )}
        </div>
        <Box className={classes.serviceCountAndIcons}>
          <Typography variant="caption" className={classes.serviceCount}>
            {totalServiceCount} service{totalServiceCount > 1 ? 's' : ''}
          </Typography>
          <Box className={classes.podIcons}>
            {hasInvalidLocations && <InvalidLocationIcon />}
            {hasUnsortedItems && (
              <Tooltip title="Contains unsorted items">
                <span>
                  <IconButton
                    className={classes.actionButton}
                    disableRipple={allowOptimization}
                    onClick={e => handleOptimizationClick(e, tech)}
                  >
                    <FontAwesomeIcon
                      className={classes.unsortedIcon}
                      icon={faExclamationCircle}
                      size="lg"
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Box>
        </Box>
      </div>
      <div>
        {isOfficeAdmin && (
          <Droppable
            key={techDroppableId}
            droppableId={techDroppableId}
            type="droppableItem"
            isDropDisabled={saving}
          >
            {provided => (
              <div
                className={clsx(classes.droppableArea)}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {tech.services.map((service, serviceIndex) => {
                  const serviceDraggableId = createServiceDraggableId({
                    routeId: route.routeId,
                    userId: tech.userId,
                    serviceId: service.scheduledServiceId,
                    routeIndex,
                    techIndex,
                    serviceIndex,
                    version: service?.version,
                  });

                  const isStartOrEnd = isRouteStartOrEnd(service);

                  const isLocked =
                    (updateMode === 'Single' && service.isSingleDayModeLocked) ||
                    (updateMode === 'All' && service.isAllDayModeLocked);

                  const hasChanged = !!changes?.[service.scheduledServiceId];

                  if (isStartOrEnd) {
                    return (
                      <SortableItem
                        key={serviceDraggableId}
                        isLocked
                        service={service}
                        condense={condense}
                        readonly={readonly}
                        showIndex={showServiceIndex}
                        indexStyle={serviceIndexStyle}
                        index={serviceIndex}
                        techColor={tech.color}
                        updateMode={updateMode}
                        view={view}
                        serviceDate={serviceDate}
                      />
                    );
                  }

                  return (
                    <Draggable
                      key={serviceDraggableId}
                      draggableId={serviceDraggableId}
                      index={serviceIndex}
                      isDragDisabled={readonly || isStartOrEnd || isLocked || saving}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <SortableItem
                            isLocked={isLocked}
                            hasChanged={hasChanged}
                            service={service}
                            draggableId={serviceDraggableId}
                            activeDraggableId={activeDraggableId}
                            isDragging={snapshot.isDragging}
                            handleSelection={(e, val: string) =>
                              toggleSelection &&
                              toggleSelection(
                                e,
                                val,
                                tech.services.filter(s => !isRouteStartOrEnd(s)),
                                tech
                              )
                            }
                            condense={condense}
                            selectedDraggableIds={selectedDraggableIds}
                            saving={saving}
                            readonly={readonly}
                            allowMultiDrag={allowMultiDrag}
                            showIndex={showServiceIndex}
                            indexStyle={serviceIndexStyle}
                            index={serviceIndex}
                            techColor={tech.color}
                            allowOptimization={allowOptimization}
                            onOptimizationClick={() =>
                              allowOptimization && onOptimizationClick?.({ tech, route })
                            }
                            updateMode={updateMode}
                            view={view}
                            serviceDate={serviceDate}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
        {!isOfficeAdmin && (
          <div>
            {tech.services.map((service, index) => {
              return (
                <div key={`${index}`} className={classes.service}>
                  <Typography>{service.siteName}</Typography>
                  <div className={classes.icons}>
                    {service.recurringServiceId && (
                      <Tooltip title="This is a recurring service">
                        <span>
                          <Autorenew fontSize="small" className={classes.recurringIcon} />
                        </span>
                      </Tooltip>
                    )}
                    {service.showBeakerIcon && (
                      <Tooltip title="Service">
                        <span>
                          <FontAwesomeIcon className={classes.icon} icon={faFlask} size="lg" />
                        </span>
                      </Tooltip>
                    )}
                    {service.showBrushIcon && (
                      <Tooltip title="Cleaning">
                        <span>
                          <FontAwesomeIcon className={classes.icon} icon={faBroom} size="lg" />
                        </span>
                      </Tooltip>
                    )}
                    {service.showWrenchIcon && (
                      <Tooltip title="Repair">
                        <span>
                          <FontAwesomeIcon
                            className={classes.icon}
                            icon={faFileInvoice}
                            size="lg"
                          />
                        </span>
                      </Tooltip>
                    )}
                    {(!service.latitude || !service.longitude) && (
                      <InvalidLocationIcon
                        accountId={service.accountId}
                        siteId={service.siteId}
                        handleSiteChange={() => handleSiteChange(route.routeId)}
                      />
                    )}
                    {!service.isSorted && (
                      <Tooltip title="This service has not been sorted">
                        <span>
                          <IconButton
                            disableRipple={allowOptimization}
                            onClick={e => handleOptimizationClick(e, tech)}
                          >
                            <FontAwesomeIcon
                              className={classes.unsortedIcon}
                              icon={faExclamationCircle}
                              size="lg"
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Card>
  );
};

const useStyles = makeStyles<Theme, { condense: boolean }>(theme => ({
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  card: {
    marginBottom: theme.spacing(1),
    '@media print': {
      'page-break-inside': 'avoid',
      marginBottom: '3rem',
    },
  },
  service: {
    padding: theme.spacing(0.75),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '6px',
  },
  icons: { display: 'flex' },
  icon: {
    borderRadius: '50%',
    color: theme.palette.primary.light,
    backgroundColor: 'rgba(0, 188, 231, .25)',
    padding: theme.spacing(1),
    margin: theme.spacing(0, 0.25),
  },
  serviceCount: {
    flex: '1 1 auto',
    paddingLeft: theme.spacing(0.5),
  },
  podIcons: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    margin: '0 2px',
    flex: '1 1 auto',
    justifyContent: ' flex-end',
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  unsortedIcon: {
    borderRadius: '50%',
    color: theme.palette.warning.main,
  },
  droppableArea: {
    minHeight: '45px',
  },
  break: {
    flexBasis: '100%',
    height: '0',
  },
  techInfoAndMap: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(0.5),
    flexDirection: 'column',
  },
  techName: {
    flex: '1 1 auto',
  },
  mapSection: {},
  recurringIcon: {
    color: theme.palette.primary.light,
  },
  serviceCountAndIcons: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  actionButton: {
    padding: 0,
  },
}));
