import { FC, forwardRef, useCallback, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Loader, GridDataFetcher, useDataGrid, Card, Link } from '../../components';
import { Box, Divider, Typography, Button } from '@mui/material';
import { IAccount, IAccountAvailableColumn } from '../../models';
import { getAccounts } from '../../fetch';
import { UserContext } from '../../context';
import { removeLocalStorage } from '../../helpers';
import { CustomersFilters } from './CustomersFilters';
import { CustomersDataGrid } from './CustomersDataGrid';
import { LSKeys } from '../../constants';
import { useDebounce } from '../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { CustomViewsWrapper } from './custom-views-wrapper';

interface ICustomersList { }

export const CustomersList: FC<ICustomersList> = () => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [filters, setFilters] = useState<{
    filterLetter?: string;
    officeId?: string;
  } | null>({});
  const [selectedLetterOption, setSelectedLetterOption] = useState<string>('');
  const [availableColumns, setAvailableColumns] = useState<IAccountAvailableColumn[]>([]);
  const [customViewSelected, setCustomViewSelected] = useState<string>('');

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchedValue, setSearchedValue] = useState<string>('');

  const dataFetcher: GridDataFetcher<IAccount> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection, isInitialSort }) => {
      try {
        if (!customViewSelected) {
          return {
            rows: [],
            rowCount: 0,
          };
        }
        const res = await getAccounts({
          sortDirection: sortDirection || 'asc',
          sortBy: isInitialSort ? '' : sortColumn,
          isInitialSort,
          page: page + 1,
          perPage,
          officeId: user?.officeId as string,
          filterLetter: selectedLetterOption,
          customViewId: customViewSelected,
          search: searchedValue,
        });

        setAvailableColumns(res.availableColumns);

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading customers, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customViewSelected, selectedLetterOption, searchedValue]
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    setPage,
    setSortDirection,
    setIsInitialSort,
  } = useDataGrid<IAccount>({
    initialOptions: {
      page: 0,
      pageSize: 50,
      gridKeyName: 'customers-grid',
      sortDirection: 'asc',
      isInitialSort: true,
    },
    dataFetcher,
  });

  useDebounce(
    () => {
      // Bug Fix: When paged to something other than 0, reset pagination on search so that results are displayed
      setPage(0);
      setSearchedValue(searchValue);
    },
    800,
    [searchValue]
  );
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{
          xs: 'column',
          sm: 'row',
        }}
        mb={2}
        gap={2}
      >
        <CustomViewsWrapper
          setCustomViewSelected={setCustomViewSelected}
          customViewSelected={customViewSelected}
          handleCustomViewChange={() => {
            setPage(0);
            setSelectedLetterOption('');
            setSearchValue('');
            setSearchedValue('');
            setSortDirection('asc');
            setIsInitialSort(true);
            // remove from local storage
            removeLocalStorage(LSKeys.CUSTOM_VIEW);
          }}
        />
        <Button
          to="/customers/new"
          startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
          color="primary"
          component={forwardRef((props: any, _ref) => {
            return <Link {...props} type="white" />;
          })}
          sx={{
            width: {
              xs: '100%',
              sm: 'auto',
            },
          }}
          data-testid="add-customer-button"
        >
          Add New Customer
        </Button>
      </Box>
      <Card>
        <Box>
          <CustomersFilters
            isLoading={isLoading}
            setSelectedLetterOption={setSelectedLetterOption}
            selectedLetterOption={selectedLetterOption}
            isDisabled={isLoading}
            searchValue={searchValue}
            searchedValue={searchedValue}
            setSearchValue={setSearchValue}
            setSearchedValue={setSearchedValue}
            applyFilters={(clearFilters?: boolean) => {
              if (clearFilters) {
                setFilters({
                  filterLetter: '',
                });
                setSearchValue('');
                setSearchedValue('');
              } else {
                setPage(0);
                setFilters({
                  ...filters,
                  filterLetter: selectedLetterOption,
                });
              }
            }}
          />
        </Box>
        <Divider />
        {(isLoading || !customViewSelected) && (
          <Box height="10rem">
            <Loader position="centered" type="inline" />
          </Box>
        )}
        {!isLoading && rows && rows?.length > 0 && (
          <Box
            sx={{
              marginTop: theme => theme.spacing(2),
              marginBottom: theme => theme.spacing(1),
            }}
          >
            <CustomersDataGrid
              loading={isLoading}
              rows={rows}
              availableColumns={availableColumns}
              rowCount={recordCount}
              page={page}
              pageSize={perPage}
              sortModel={sortModel}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              onSortModelChange={onSortModelChange}
            />
          </Box>
        )}
        {!isLoading && (rows?.length === 0 || !rows) && customViewSelected && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="10rem"
          >
            <Typography>There are no customers to display.</Typography>
          </Box>
        )}
      </Card>
    </>
  );
};
