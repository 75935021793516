import { FC, useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Button, IconButton, TextField, useMediaQuery } from '@mui/material';
import { faTrash, faEdit, faTable , faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Loader,
  Modal,
  ModalSaveSection,
  CardTitle,
  Card,
  ImageModal,
  useDataGrid,
  GridDataFetcher,
  ServerSideDataGrid,
} from '../../components';
import { MultiImageUpload } from '../../components/file/multi-image-upload';
import { createSitePhotos, deleteSitePhoto, getSitePhotos, updateSitePhoto } from '../../fetch';
import { formatDate } from '../../helpers';
import { ISitePhoto } from '../../models';
import { useConfirm } from '../../hooks';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import ImageGrid from '../../components/data-grid/ImageGrid';

interface ISiteImagesProps {
  siteId: string | number;
  isEditable?: boolean;
  showPagination?: boolean;
  isExpanded?: boolean;
  isCollapsible?: boolean;
  initialExpand?: boolean;
  onAddPhotos?: (photos: string[]) => void;
  onRemovePhoto?: (photo: string) => void;
}

export const SiteImages: FC<ISiteImagesProps> = ({
  siteId,
  isEditable = false,
  showPagination = false,
  isExpanded,
  isCollapsible = true,
  initialExpand = true,
  onAddPhotos,
  onRemovePhoto,
}) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const classes = useStyles({ isEditable });
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const [isModalOpen, setisModalOpen] = useState(false);
  const [source, setSource] = useState<string>('');

  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);
  const [isDeletingPhoto, setIsDeletingPhoto] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentImage, setCurrentImage] = useState<ISitePhoto | null>(null);
  const [imageValue, setImageValue] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [viewImageGrid, setViewImageGrid] = useState(false);

  const dataFetcher: GridDataFetcher<ISitePhoto> = useCallback(
    async ({ sortColumn, sortDirection, page, perPage }) => {
      try {
        const res = await getSitePhotos(siteId, {
          perPage: showPagination ? perPage : -1,
          sortBy: sortColumn || 'whenTaken',
          sortDirection: sortDirection || 'desc',
          page: page + 1,
        });
        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading site photos, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const {
    rows,
    setRows,
    page,
    pageSize: perPage,
    isLoading: isLoadingSitePhotos,
    refetch: fetchSitePhotos,
    onSortModelChange,
    onPageChange,
    rowCount: recordCount,
    onPageSizeChange,
  } = useDataGrid<ISitePhoto>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'site-photos-grid',
      sortColumn: 'whenTaken',
      sortDirection: showPagination ? 'desc' : 'asc',
    },
    dataFetcher,
  });

  const uploadSitePhoto = async (payload: File, photo: ISitePhoto) => {
    setIsUploadingPhoto(true);
    try {
      await createSitePhotos(siteId, payload, photo);
      enqueueSnackbar(`Site Photo Uploaded!`, {
        variant: 'success',
      });
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error uploading site photos, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsUploadingPhoto(false);
    }
  };

  const removeSitePhoto = async (sitePhotoId: string) => {
    setIsDeletingPhoto(true);
    try {
      await deleteSitePhoto(siteId, sitePhotoId);
      enqueueSnackbar(`Site Photo Deleted!`, {
        variant: 'success',
      });
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error deleting site photo, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsDeletingPhoto(false);
      await fetchSitePhotos();
    }
  };

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'thumbnail',
        headerName: '',
        disableColumnMenu: true,
        flex: 1,
        maxWidth: 150,
        sortable: false,
        renderCell: (params: GridRenderCellParams<ISitePhoto>) => {
          const { row: photo } = params;
          return (
            <Button
              variant="text"
              onClick={() => {
                setSource(photo.urlPath);
                setisModalOpen(true);
              }}
              className={classes.imgButton}
            >
              <img src={photo.urlPath} alt={photo.title} style={{ width: '100%' }} />
            </Button>
          );
        },
      },
      {
        field: 'title',
        headerName: 'Title',
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params: GridRenderCellParams<ISitePhoto>) => {
          const { row: photo } = params;

          return (
            (
              <Button
                variant="text"
                disabled={isUploadingPhoto || isLoadingSitePhotos || isDeletingPhoto}
                onClick={() => {
                  setSource(photo.urlPath);
                  setisModalOpen(true);
                }}
                sx={{ padding: 0 }}
              >
                {photo.title}
              </Button>
            ) || '--'
          );
        },
      },
      {
        field: 'whenTaken',
        headerName: 'Upload Date',
        disableColumnMenu: true,
        minWidth: 125,
        renderCell: (params: GridRenderCellParams<ISitePhoto>) => {
          const { row: photo } = params;

          return (
            (
              <span title={isEditable ? undefined : 'Upload Date'}>
                {!!photo.whenTaken ? formatDate(photo.whenTaken) : ''}
              </span>
            ) || '--'
          );
        },
      },
      {
        field: 'actions',
        headerName: '',
        disableColumnMenu: true,
        sortable: false,
        minWidth: 120,
        maxWidth: 300,
        align: 'center',
        flex: 1,
        renderCell: (params: GridRenderCellParams<ISitePhoto>) => {
          const { row: photo } = params;

          if (isEditable) {
            return (
              <Box textAlign="right" display="flex" justifyContent="flex-end">
                <Box className={clsx('print--none')} display="flex">
                  <IconButton
                    color="error"
                    title="Delete Photo"
                    disabled={
                      isUploadingPhoto ||
                      isLoadingSitePhotos ||
                      isDeletingPhoto ||
                      rows.some(photo => !photo.whenTaken)
                    }
                    onClick={async () => {
                      const result = await confirm('Are you sure you want to delete this?');
                      if (result) {
                        await removeSitePhoto(photo.sitePhotoId);
                        onRemovePhoto?.(photo.title);
                      } else {
                        return;
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} size="sm" />
                  </IconButton>
                  <IconButton
                    color="primary"
                    disabled={
                      isUploadingPhoto ||
                      isLoadingSitePhotos ||
                      isDeletingPhoto ||
                      rows.some(photo => !photo.whenTaken)
                    }
                    title={`Edit Site Photo Name`}
                    onClick={() => {
                      setImageValue('');
                      setCurrentImage(null);
                      setShowEditModal(true);
                      setCurrentImage(photo);
                      setImageValue(photo.title);
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} size="sm" />
                  </IconButton>
                </Box>
              </Box>
            );
          }
          return null;
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSitePhotos, isEditable, rows, isLoadingSitePhotos]);

  const toggleGrid = () => {
    setViewImageGrid(!viewImageGrid);
  }

  return (
    <Card>
      <CardTitle
        title="Site Photo(s)"
        withExpand={isCollapsible}
        initialExpand={initialExpand}
        overrideExpand={isExpanded}
        mobileWrap
        action={
          isEditable ? (
            <MultiImageUpload
              handleFileChange={async fileImages => {
                setRows(prev => [...prev, ...fileImages]);
                await Promise.all(
                  fileImages.map(async photo => {
                    if (photo?.whenTaken) {
                      return;
                    }
                    if (photo?.file) {
                      await uploadSitePhoto(photo.file, photo);
                    }
                  })
                );
                await fetchSitePhotos();
                onAddPhotos?.(fileImages.filter(img => !img?.whenTaken).map(img => img.title));
              }}
              images={rows.map(photo => photo.urlPath)}
              disabled={isLoadingSitePhotos}
              photoId="sitePhotoId"
              buttonSize="small"
              buttonColor="secondary"
              isButtonFullWidth={isMobile}
            />
          ) : undefined
        }
      >
        <Box
          textAlign="right"
          display="flex"
          justifyContent="flex-end"
          className="print--none"
        >
          <IconButton
            color="primary"
            onClick={toggleGrid}
            disabled={!viewImageGrid}
            title={`Show site images as a list`}
          >
            <FontAwesomeIcon icon={faList} size="sm" />
          </IconButton>
          <IconButton
            color="primary"
            onClick={toggleGrid}
            title={`Show site images as a tile gallery`}
            disabled={viewImageGrid}
          >
            <FontAwesomeIcon icon={faTable} size="sm" />
          </IconButton>
        </Box>
        <ImageModal isOpen={isModalOpen} onClose={() => setisModalOpen(false)} source={source} />
        <Modal
          maxWidth="xs"
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          title="Edit Site Photo Name"
        >
          {isUpdating && <Loader type="overlay" position="centered" />}
          <Box mt={2}>
            <TextField
              fullWidth
              value={imageValue}
              onChange={e => setImageValue(e.target.value)}
              label="Name"
              size="small"
              inputProps={{
                maxLength: 255,
              }}
            />
          </Box>
          <ModalSaveSection
            isSaveDisabled={!imageValue}
            handleSave={async () => {
              try {
                setIsUpdating(true);
                await updateSitePhoto(siteId, currentImage?.sitePhotoId, {
                  title: imageValue,
                });
                fetchSitePhotos();
                setShowEditModal(false);
              } catch (error: any) {
                enqueueSnackbar(error?.Detail ?? `Error updating site photo, please try again.`, {
                  variant: 'error',
                });
              } finally {
                setIsUpdating(false);
              }
            }}
            handleCancel={() => {
              setShowEditModal(false);
            }}
          />
        </Modal>
        {viewImageGrid ? (
          <div>
            <ImageGrid openModal={setisModalOpen} setSource={setSource} images={rows} />{' '}
          </div>
        ) : (
          <div>
            <ServerSideDataGrid
              getRowId={(row: ISitePhoto) => {
                return row.sitePhotoId!;
              }}
              rows={rows}
              columns={columns}
              autoHeight
              columnHeaderHeight={36}
              onSortModelChange={onSortModelChange}
              hideFooter={!showPagination}
              loading={isLoadingSitePhotos || isUploadingPhoto || isDeletingPhoto}
              rowCount={recordCount}
              page={page}
              pageSize={perPage}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              noResultsMessage="No site photos found."
            />
          </div>
        )}
      </CardTitle>
    </Card>
  );
};

const useStyles = makeStyles<Theme, { isEditable: boolean }>(theme => ({
  imgButton: {
    padding: 0,
    maxHeight: 80,
    maxWidth: 80,
    minHeight: 30,
    minWidth: 30,
    overflow: 'hidden',
    position: 'relative',
    margin: 0,
    borderRadius: 0,
    display: 'block',
  },
}));
