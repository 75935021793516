import { FC, useContext, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, Fade, Stack, Grid, useMediaQuery } from '@mui/material';
import {
  ConfirmCheckboxModal,
  ConfirmPrompt,
  FloatingToolbar,
  Loader,
  Modal,
  Page,
  SaveButton,
  Stepper,
} from '../../../components';
import { UserContext } from '../../../context';
import {
  IBillingGroup,
  ISalesTax,
  ICreateOneTimeService,
  IOneTimeServiceDetail,
  IUpdateOneTimeService,
  IUser,
  IAccountSimple,
} from '../../../models';
import {
  getBillingGroups,
  getSalesTaxes,
  getOneTimeService,
  createOneTimeService,
  updateOneTimeService,
  getRepairStatus,
  closeOneTimeService,
  getTotalTransactionAmountByAccount,
} from '../../../fetch';
import { handleCreateNewCustomer, handleGetNewCustomerSite } from '../../customers';
import { useQuery } from 'react-query';
import { alphaSort, convertToNumber, phoneRegExp } from '../../../helpers';
import { OTSEstimates } from './ots-estimates';
import { OTSVisits } from './ots-visits';
import { OTSInvoices } from './ots-invoices';
import { OTSServiceDetails } from './ots-service-details';
import { OTSCustomerInfo } from './ots-customer-info';
import { defaultSaveAndContinueMessage, defaultUnsavedChangesMessage } from '../../../constants';
import { camel } from 'case';
import { useConfirm } from '../../../hooks';

interface IOTSDetailPage {
  isModalOpen?: boolean;
  handleModalClose?: () => void;
  isModal?: boolean;
  currentRepairId?: string;
}

const CUSTOMER_DETAIL_FORM_SCHEMA = Yup.object().shape({
  //General Info
  accountName: Yup.string().max(255, 'Max 255 characters').required('Required'),
  firstName: Yup.string().max(255, 'Max 255 characters').required('Required'),
  lastName: Yup.string().max(255, 'Max 255 characters').required('Required'),
  //Contact Info
  phone: Yup.string()
    .matches(phoneRegExp, {
      excludeEmptyString: true,
      message: 'Invalid phone number',
    })
    .required('Required'),
  email: Yup.string().max(255, 'Max 255 characters').email('Email address invalid'),
  addressName: Yup.string().max(255, 'Max 255 characters').required('Required'),
  street: Yup.string().max(255, 'Max 255 characters').required('Required'),
  city: Yup.string().max(255, 'Max 255 characters').required('Required'),
  state: Yup.string().max(255, 'Max 255 characters').required('Required'),
  postalCode: Yup.string().max(255, 'Max 255 characters').required('Required'),
  latitude: Yup.string(),
  longitude: Yup.string(),
  //Billing Info
  billingGroupId: Yup.string().required('Required'),
  salesTaxId: Yup.string().required('Required'),
  status: Yup.string(),
});

const OTSDetailPageSchema = Yup.object().shape({
  customerType: Yup.string(),
  //Customer Details
  accountId: Yup.mixed().when('customerType', {
    is: (val: string) => val === 'ExistingCustomer',
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  siteId: Yup.mixed().when('customerType', {
    is: (val: string) => val === 'NewCustomer',
    then: Yup.string().notRequired().nullable(),
    otherwise: Yup.string().required('Required'),
  }),
  newCustomer: Yup.mixed().when('customerType', {
    is: (val: string) => val !== 'NewCustomer',
    then: Yup.string().notRequired().nullable(),
    otherwise: CUSTOMER_DETAIL_FORM_SCHEMA,
  }),
  serviceTypeId: Yup.string().nullable(),
  customAgreementId: Yup.string().nullable(),
  // Service Details
  problemDescription: Yup.string(),
  repairNotes: Yup.string(),
  repairStatus: Yup.string().required('Required'),
  version: Yup.string().nullable(),
});

const steps = [
  'Agreement Sent',
  'Agreement Signed',
  'Visit Scheduled',
  'Work Completed',
  'Invoice Created',
  'Invoice Posted',
  'Paid',
];

export const OTSDetailPage: FC<IOTSDetailPage> = ({
  isModalOpen,
  handleModalClose,
  isModal,
  currentRepairId,
}) => {
  const isMobile = useMediaQuery(`(max-width: 1199px)`);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const history = useHistory();
  const confirm = useConfirm();

  const params = new URLSearchParams(window.location.search);
  const redirect = params?.get?.('redirect');

  const { repairId: repairIdParam }: { repairId: string } = useParams();
  const repairId = repairIdParam || currentRepairId!;
  const isNewService = repairId === 'new';
  const accountIdParam = params?.get?.('accountId');
  const siteIdParam = params?.get?.('siteId');

  const [selectedCustomerType, setSelectedCustomerType] = useState('ExistingCustomer');

  const [service, setService] = useState<IOneTimeServiceDetail | null>(null);
  const [isLoadingService, setIsLoadingService] = useState(false);
  const [hasServiceLoaded, setServiceLoaded] = useState<boolean>(false);
  const [isAllExpanded, setIsAllExpanded] = useState<boolean | undefined>(true);
  const [billingGroups, setBillingGroups] = useState<IBillingGroup[]>([]);
  const [isLoadingBillingGroups, setIsLoadingBillingGroups] = useState(false);
  const [salesTaxes, setSalesTaxes] = useState<ISalesTax[]>([]);
  const [isLoadingSalesTaxes, setIsLoadingSalesTaxes] = useState(false);
  const [isAgreementSigned, setIsAgreementSigned] = useState(false);
  const [isInvoicePaid, setIsInvoicePaid] = useState(false);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({ 0: true });
  const [shouldRefetchAccounts, setShouldRefetchAccounts] = useState(false);
  const [isClosingInvoiceWarningModalOpen, setIsClosingInvoiceWarningModalOpen] = useState(false);
  const [isClosingPaymentWarningModalOpen, setIsClosingPaymentWarningModalOpen] = useState(false);
  const [isUpdatingOneTimeService, setIsUpdatingOneTimeService] = useState(false);
  const [isHandlingEstimateNavigation, setIsHandlingEstimateNavigation] = useState(false);
  const [isHandlingScheduler, setIsHandlingScheduler] = useState(false);
  const [estimateNavigationUrl, setEstimateNavigationUrl] = useState<string | null>(null);
  const [isClosingService, setIsClosingService] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState<IAccountSimple | null>(null);
  const [isSchedulerOpen, setIsSchedulerOpen] = useState(false);

  const [isClosingVisitsOpenWarningModalOpen, setIsClosingVisitsOpenWarningModalOpen] =
    useState(false);
  const [oTSGridRecordCount, setOTSGridRecordCount] = useState<number | null>(null);
  const [isOTSGridLoading, setIsOTSGridIsLoading] = useState(false);

  const getCustomerType = (service?: IOneTimeServiceDetail, values?: IOneTimeServiceDetail) => {
    // If accountId, set Existing Customer
    if (!!service?.accountId) {
      return setSelectedCustomerType('ExistingCustomer');
    }
    // Else set to default
    return setSelectedCustomerType('');
  };

  const { refetch: refetchRepairStatus } = useQuery<string[]>(
    ['getRepairStatus', repairId],
    () => getRepairStatus(repairId),
    {
      enabled: !isNewService,
      onSuccess: (statuses: string[]) => {
        const formattedStatuses = statuses?.map(step => {
          return camel(step);
        });
        const completedSteps = steps.reduce(
          (prev, curr, index) => ({
            ...prev,
            [index]: formattedStatuses.includes(camel(curr)) ? true : false,
          }),
          {}
        );
        setCompleted(completedSteps);
        const agreementSignedIndex =
          formattedStatuses.findIndex(step => step === 'agreementSigned') ?? 0;
        setIsAgreementSigned(Boolean(Object.values(completedSteps)[agreementSignedIndex]));
        setIsInvoicePaid(statuses.includes('Paid'));
      },
    }
  );
  const fetchBillingGroups = async () => {
    setIsLoadingBillingGroups(true);
    try {
      const res = await getBillingGroups({ perPage: -1, officeId: user?.officeId });
      const sorted = alphaSort(res.records, 'description');
      setBillingGroups && setBillingGroups(sorted);
    } catch (error) {
      enqueueSnackbar(`Error loading billing groups, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingBillingGroups(false);
    }
  };
  const fetchSalesTaxes = async () => {
    setIsLoadingSalesTaxes(true);
    try {
      const res = await getSalesTaxes({ perPage: -1, officeId: user?.officeId });
      const sorted: ISalesTax[] = alphaSort(res.records, 'description');
      setSalesTaxes && setSalesTaxes(sorted);
    } catch (error) {
      enqueueSnackbar(`Error loading sales taxes, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingSalesTaxes(false);
    }
  };

  const fetchService = async (newServiceId?: string, showLoading?: boolean) => {
    try {
      if (showLoading) {
        setIsLoadingService(true);
      }
      const response = await getOneTimeService(newServiceId ?? repairId);
      setService(response);
      getCustomerType(response);
      setServiceLoaded(true);
      // wait till the page is loading before flipping this flag so the form is in correct state
      setTimeout(() => {
        setServiceLoaded(false);
      }, 500);
    } catch (err: any) {
      if (err?.Status > 400) {
        enqueueSnackbar('Redirecting...', {
          variant: 'info',
        });
        enqueueSnackbar(err?.Detail, {
          variant: 'error',
        });
        return setTimeout(function () {
          history.push(redirect ?? '/services/ots');
        }, 3000);
      } else {
        enqueueSnackbar(`Error loading service, please try again.`, {
          variant: 'error',
        });
      }
    } finally {
      setIsLoadingService(false);
    }
  };

  const isEditing = useMemo(() => {
    if (isNewService) {
      return false;
    } else {
      return true;
    }
  }, [isNewService]);

  const handleUpdateOneTimeService = async (
    id: string,
    values: IUpdateOneTimeService,
    isClosing?: boolean,
    missingInvoice?: boolean,
    missingPayment?: boolean
  ) => {
    try {
      setIsUpdatingOneTimeService(true);
      await updateOneTimeService(id, values);
      if (isClosing) {
        await closeOneTimeService(id, missingInvoice ?? false, missingPayment ?? false);
      }
      enqueueSnackbar('Service saved!', { variant: 'success' });
      if (!isHandlingEstimateNavigation) {
        history.push(redirect ?? '/services/ots');
      } else {
        history.push(
          estimateNavigationUrl ??
            `/customers/${accountIdParam}/estimates/new${redirect ? `?redirect=${redirect}` : ''}${
              siteIdParam ? `&siteId=${siteIdParam}` : ''
            }`
        );
      }
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error saving service. Please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsUpdatingOneTimeService(false);
      setEstimateNavigationUrl(null);
      setIsHandlingEstimateNavigation(false);
    }
  };

  useEffect(() => {
    if (!isNewService) {
      fetchService(undefined, true);
    }
    fetchBillingGroups();
    fetchSalesTaxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewService]);

  if (isLoadingService) {
    return isModal ? (
      <Modal open={isModalOpen!} onClose={handleModalClose!} maxWidth="lg" title="Edit Service">
        <Fade in={isModalOpen!}>
          <Box mt={3}>
            <Box minHeight="30rem" display="flex" alignItems="center" justifyContent="center">
              <Loader position="centered" />
            </Box>
          </Box>
        </Fade>
      </Modal>
    ) : (
      <Page
        title="Loading..."
        breadcrumb={{
          text: 'One-Time Services',
          title: 'Back to One-Time Services',
          link: `/services/ots`,
        }}
      >
        <Loader position="centered" />
      </Page>
    );
  }

  return (
    <>
      <Formik
        enableReinitialize={hasServiceLoaded}
        initialValues={{
          customerType: !!service?.accountId ? 'ExistingCustomer' : selectedCustomerType,
          accountId: service?.accountId ?? '',
          siteId: service?.siteId ?? '',
          serviceTypeId: service?.serviceTypeId ?? '',
          customAgreementId: service?.customAgreementId ?? '',
          signature: service?.signature ?? '',
          newCustomer: null,
          problemDescription: service?.problemDescription ?? '',
          repairNotes: service?.repairNotes ?? '',
          repairStatus: service?.repairStatus ?? 'Open',
          version: service?.version ?? null,
        }}
        validationSchema={OTSDetailPageSchema}
        onSubmit={async values => {
          const isClosing =
            (service?.repairStatus !== 'Closed' || !service?.repairStatus) &&
            values.repairStatus === 'Closed';
          const balance = isClosing
            ? await getTotalTransactionAmountByAccount(values.accountId)
            : 0;
          const showConfirmInvoice = !service?.invoiceId;
          const showConfirmPayment = isInvoicePaid ? false : balance > 0;
          const showConfirmOpenVisits = oTSGridRecordCount! > 0;

          const handleRedirect = (id: string | undefined, payload: any | undefined) => {
            const repairId = id && id !== 'new' ? `&repairId=${id}` : '';
            const siteId =
              payload.siteId && payload.siteId !== 'new' ? `&siteId=${payload.siteId}` : '';
            // If creating new OTS and adding a new estimate
            if (isHandlingEstimateNavigation && !!repairId) {
              return history.push(
                `/customers/${payload.accountId}/estimates/new?redirect=/services/ots/${id}${repairId}${siteId}`
              );
            }
            // If not creating a new OTS and adding a new estimate
            if (isHandlingEstimateNavigation && !repairId) {
              return history.push(
                `/customers/${payload.accountId}/estimates/new?redirect=/estimates?${id}${siteId}`
              );
            }
            return history.push(`/services/ots/${id}`);
          };
          try {
            if (!!values.newCustomer && values.customerType === 'NewCustomer' && isNewService) {
              const newAccountId = await handleCreateNewCustomer(
                values.newCustomer,
                enqueueSnackbar,
                user as IUser
              );

              if (newAccountId && typeof newAccountId === 'string') {
                const newSiteId = await handleGetNewCustomerSite(newAccountId, user as IUser);
                const payload: ICreateOneTimeService = {
                  accountId: newAccountId ?? null,
                  siteId: newSiteId ?? null,
                  problemDescription: values.problemDescription,
                  repairNotes: values.repairNotes,
                  repairStatus: values.repairStatus,
                  serviceTypeId: values.serviceTypeId
                    ? convertToNumber(values.serviceTypeId)
                    : null,
                  customAgreementId: values.customAgreementId ?? null,
                };
                const newOtsId = await createOneTimeService(payload);
                setShouldRefetchAccounts(true);
                handleRedirect(newOtsId, payload);
                enqueueSnackbar('Service saved!', { variant: 'success' });
                fetchService(newOtsId, true);
              } else {
                enqueueSnackbar('Error creating new customer.', { variant: 'error' });
                return;
              }
            } else if (isNewService) {
              const payload: ICreateOneTimeService = {
                accountId: values.accountId,
                siteId: values.siteId,
                problemDescription: values.problemDescription,
                repairNotes: values.repairNotes,
                repairStatus: values.repairStatus,
                serviceTypeId: values.serviceTypeId ? convertToNumber(values.serviceTypeId) : null,
                customAgreementId: values.customAgreementId ?? null,
              };
              const newOtsId = await createOneTimeService(payload);
              handleRedirect(newOtsId, payload);
              enqueueSnackbar('Service saved!', { variant: 'success' });
            } else {
              const payload: IUpdateOneTimeService = {
                problemDescription: values.problemDescription,
                repairNotes: values.repairNotes,
                repairStatus: values.repairStatus,
                serviceTypeId: values.serviceTypeId ? convertToNumber(values.serviceTypeId) : null,
                customAgreementId: values.customAgreementId ?? null,
              };

              if (isClosing && showConfirmOpenVisits) {
                setIsClosingVisitsOpenWarningModalOpen(true);
              } else if (isClosing && showConfirmInvoice) {
                setIsClosingInvoiceWarningModalOpen(true);
              } else if (isClosing && showConfirmPayment) {
                setIsClosingPaymentWarningModalOpen(true);
              } else {
                handleUpdateOneTimeService(repairId, payload, isClosing);
              }
            }
          } catch (err: any) {
            enqueueSnackbar(err?.Detail || `Error saving service. Please try again.`, {
              variant: 'error',
            });
          } finally {
            setIsHandlingEstimateNavigation(false);
            setIsClosingService(false);
          }
        }}
      >
        {({
          isSubmitting,
          setSubmitting,
          values,
          initialValues,
          setFieldValue,
          touched,
          handleSubmit,
          dirty,
          handleBlur,
          handleChange,
          errors,
          isValid,
        }) => {
          const onEstimateNavigation = async (url: string) => {
            setIsHandlingEstimateNavigation(true);
            try {
              if (dirty && !isSubmitting && !isLoadingService && !isUpdatingOneTimeService) {
                const result = await confirm(defaultSaveAndContinueMessage, {
                  confirmationText: 'Save & Continue',
                }); // Specific wording, intended to be different from default unsaved changes messaging
                if (!!result) {
                  setEstimateNavigationUrl(url);
                  handleSubmit();
                } else {
                  setEstimateNavigationUrl(null);
                }
              } else {
                history.push(url); // For edit, if no changes, proceed to estimate detail without stopping the user
              }
            } catch (error) {}
          };
          const handleSchedularSaveAndContinue = async () => {
            try {
              setIsHandlingScheduler(true);
              const result = await confirm(defaultSaveAndContinueMessage, {
                confirmationText: 'Save & Continue',
              }); // Specific wording, intended to be different from default unsaved changes messaging
              if (!!result) {
                handleSubmit();
                setIsSchedulerOpen(true);
              } else {
                setIsHandlingScheduler(false);
                setIsSchedulerOpen(false);
              }
            } catch (error) {}
          };
          const isSaveDisabled =
            !dirty ||
            isSubmitting ||
            !isValid ||
            (!values.serviceTypeId && !values.customAgreementId) ||
            isUpdatingOneTimeService ||
            isClosingService;
          const children = (
            <>
              {!isNewService && (
                <Box mb={1}>
                  <Stepper steps={steps} completed={completed} isSequential={false} />
                </Box>
              )}
              <div>
                {(isSubmitting || isUpdatingOneTimeService || isClosingService) && (
                  <Loader position="centered" type="overlay" />
                )}
                {/* Form sections */}
                {(service || !isEditing) && !isLoadingService && !!values.customerType && (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      className="print--none"
                    >
                      <Button
                        variant="text"
                        onClick={() => setIsAllExpanded(!isAllExpanded ? true : false)}
                        startIcon={isAllExpanded ? <ExpandLess /> : <ExpandMore />}
                        data-testid="expand-customer-info-button"
                      >
                        {isAllExpanded ? 'Collapse All' : 'Expand All'}
                      </Button>
                    </Box>
                    <Form onSubmit={handleSubmit}>
                      <Stack gap={2}>
                        {values.customerType !== 'NewCustomer' && (
                          <OTSCustomerInfo
                            values={values}
                            setFieldValue={setFieldValue}
                            isNewService={isNewService}
                            handleChange={handleChange}
                            touched={touched}
                            errors={errors}
                            handleBlur={handleBlur}
                            service={service}
                            billingGroups={billingGroups}
                            salesTaxes={salesTaxes}
                            accountIdParam={accountIdParam ?? service?.accountId}
                            siteIdParam={values?.siteId}
                            shouldRefetchAccounts={shouldRefetchAccounts}
                            showConfirm={
                              dirty &&
                              !isSubmitting &&
                              !isLoadingService &&
                              !isUpdatingOneTimeService &&
                              !isHandlingEstimateNavigation &&
                              !isHandlingScheduler
                            }
                            shouldUnload={!isHandlingEstimateNavigation && dirty}
                            setCurrentCustomer={setCurrentCustomer}
                          />
                        )}
                        <OTSServiceDetails
                          values={values}
                          setFieldValue={setFieldValue}
                          isNewService={isNewService}
                          touched={touched}
                          errors={errors}
                          handleBlur={handleBlur}
                          isAllExpanded={isAllExpanded}
                          service={service}
                          billingGroups={billingGroups}
                          isLoadingBillingGroups={isLoadingBillingGroups}
                          salesTaxes={salesTaxes}
                          isLoadingSalesTaxes={isLoadingSalesTaxes}
                          showConfirm={
                            dirty &&
                            !isSubmitting &&
                            !isLoadingService &&
                            !isUpdatingOneTimeService &&
                            !isHandlingEstimateNavigation &&
                            !isHandlingScheduler
                          }
                          shouldUnload={!isHandlingEstimateNavigation && dirty}
                        />
                      </Stack>
                      <FloatingToolbar isModal={isModal}>
                        <Button
                          color="inherit"
                          disabled={isSubmitting || isUpdatingOneTimeService || isClosingService}
                          onClick={() => {
                            if (isModal && handleModalClose) {
                              return handleModalClose();
                            }
                            history.push(params.get('redirect') ?? '/services/ots');
                          }}
                          data-testid="cancel-button"
                        >
                          Cancel
                        </Button>
                        <SaveButton disabled={isSaveDisabled} />
                        {values.repairStatus !== 'Closed' &&
                          initialValues.repairStatus !== 'Closed' &&
                          !isModal &&
                          !isNewService && (
                            <Button
                              color="primary"
                              title="Change One-Time Service to Closed Status"
                              disabled={
                                isSubmitting ||
                                isClosingService ||
                                isUpdatingOneTimeService ||
                                isOTSGridLoading
                              }
                              onClick={() => {
                                setIsClosingService(true);
                                setFieldValue('repairStatus', 'Closed');
                                setSubmitting(true);
                                handleSubmit();
                              }}
                              data-testid="close-ots-button"
                            >
                              Close OTS
                            </Button>
                          )}
                      </FloatingToolbar>

                      <Modal
                        open={isClosingVisitsOpenWarningModalOpen}
                        onClose={() => {
                          setIsClosingVisitsOpenWarningModalOpen(false);
                          setFieldValue('repairStatus', service?.repairStatus ?? 'Open');
                        }}
                        title={
                          'There are still open visits. Would you still like to close this job?'
                        }
                        maxWidth="md"
                        showCloseButton={true}
                      >
                        <Fade in={true}>
                          <div>
                            <Box marginTop={'1rem'}></Box>
                            <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
                              <Button
                                type="button"
                                color="inherit"
                                onClick={() => {
                                  setIsClosingVisitsOpenWarningModalOpen(false);
                                  setFieldValue('repairStatus', service?.repairStatus ?? 'Open');
                                }}
                                data-testid="cancel-button"
                              >
                                Cancel
                              </Button>

                              <Button
                                onClick={() => {
                                  setIsClosingVisitsOpenWarningModalOpen(false);
                                  handleUpdateOneTimeService(
                                    repairId,
                                    {
                                      problemDescription: values.problemDescription,
                                      repairNotes: values.repairNotes,
                                      repairStatus: values.repairStatus,
                                      serviceTypeId: values.serviceTypeId
                                        ? convertToNumber(values.serviceTypeId)
                                        : null,
                                      customAgreementId: values.customAgreementId ?? null,
                                    },
                                    true,
                                    false,
                                    false
                                  );
                                }}
                                type="button"
                                color="secondary"
                                data-testid="ok-button"
                              >
                                Ok
                              </Button>
                            </Stack>
                          </div>
                        </Fade>
                      </Modal>
                      <ConfirmCheckboxModal
                        isOpen={isClosingInvoiceWarningModalOpen}
                        handleClose={() => {
                          setIsClosingInvoiceWarningModalOpen(false);
                          setFieldValue('repairStatus', service?.repairStatus ?? 'Open');
                        }}
                        handleSubmit={() => {
                          setIsClosingInvoiceWarningModalOpen(false);
                          handleUpdateOneTimeService(
                            repairId,
                            {
                              problemDescription: values.problemDescription,
                              repairNotes: values.repairNotes,
                              repairStatus: values.repairStatus,
                              serviceTypeId: values.serviceTypeId
                                ? convertToNumber(values.serviceTypeId)
                                : null,
                              customAgreementId: values.customAgreementId ?? null,
                            },
                            true,
                            true,
                            false
                          );
                        }}
                        title="The invoice is either not finalized or not paid.  Do you still want to close this job? "
                        checkboxLabel="I confirm that no invoice needs to be issued for this work."
                      />
                      <ConfirmCheckboxModal
                        isOpen={isClosingPaymentWarningModalOpen}
                        handleClose={() => setIsClosingPaymentWarningModalOpen(false)}
                        handleSubmit={() => {
                          handleUpdateOneTimeService(
                            repairId,
                            {
                              problemDescription: values.problemDescription,
                              repairNotes: values.repairNotes,
                              repairStatus: values.repairStatus,
                              serviceTypeId: values.serviceTypeId
                                ? convertToNumber(values.serviceTypeId)
                                : null,
                              customAgreementId: values.customAgreementId ?? null,
                            },
                            true,
                            false,
                            true
                          );
                        }}
                        title="OTS Invoice may be unpaid and Customer has an outstanding balance"
                        checkboxLabel="I confirm that payment has been received for this work and this service can be closed."
                      />
                    </Form>
                  </>
                )}
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <Stack gap={2}>
                        <OTSEstimates
                          accountId={values?.accountId}
                          siteId={values.siteId}
                          officeId={user?.officeId}
                          repairId={repairId}
                          isAllExpanded={isAllExpanded}
                          isModal={isModal}
                          isAgreementSigned={isAgreementSigned}
                          refetchRepairStatus={() => refetchRepairStatus()}
                          onNavigation={onEstimateNavigation}
                          shouldAddBeDisabled={isSaveDisabled}
                        />
                        {isMobile && (
                          <OTSVisits
                            repairId={repairId}
                            isExpanded={isAllExpanded}
                            service={service}
                            refetchRepairStatus={refetchRepairStatus}
                            hidePagination={true}
                            isModal={isModal}
                            isSchedulerOpen={isSchedulerOpen}
                            shouldSchedulerBeDisabled={isNewService && isSaveDisabled}
                            saveAndContinue={handleSchedularSaveAndContinue}
                            setOTSGridRecordCount={val => setOTSGridRecordCount(val)}
                            setOTSGridLoading={val => setIsOTSGridIsLoading(val)}
                          />
                        )}
                        {!isNewService && (
                          <OTSInvoices
                            repairId={repairId}
                            paymentMethodId={service?.paymentMethodId ?? null}
                            isExpanded={isAllExpanded}
                            shouldShowLaborModal={service?.promptForLaborRate}
                            enableCreateInvoice={isAgreementSigned}
                            refetchRepairStatus={() => refetchRepairStatus()}
                            isModal={isModal}
                            isOTSStatusOpen={
                              service?.repairStatus !== 'Closed' &&
                              service?.repairStatus !== 'Cancelled' &&
                              !isInvoicePaid
                            }
                            currentCustomer={currentCustomer}
                            creditCardLast4Digits={service?.creditCardLastFourDigits}
                            creditCardExpirationDate={service?.creditCardExpirationInfo}
                          />
                        )}
                      </Stack>
                    </Grid>
                    {!isMobile && (
                      <Grid item xs={12} lg={6}>
                        <OTSVisits
                          repairId={repairId}
                          isExpanded={isAllExpanded}
                          service={service}
                          refetchRepairStatus={refetchRepairStatus}
                          hidePagination={true}
                          isModal={isModal}
                          isSchedulerOpen={isSchedulerOpen}
                          shouldSchedulerBeDisabled={isNewService && isSaveDisabled}
                          saveAndContinue={handleSchedularSaveAndContinue}
                          setOTSGridRecordCount={val => setOTSGridRecordCount(val)}
                          setOTSGridLoading={val => setIsOTSGridIsLoading(val)}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </div>
            </>
          );
          return isModal ? (
            <Modal
              open={isModalOpen!}
              onClose={handleModalClose!}
              maxWidth="lg"
              title="Edit Service"
            >
              <Fade in={isModalOpen!}>
                <Box mt={2} mb={4}>
                  {children}
                </Box>
              </Fade>
            </Modal>
          ) : (
            <Page
              title={isNewService ? 'New Service' : 'Edit Service'}
              breadcrumb={{
                text: 'One-Time Services',
                title: 'Back to One-Time Services',
                link: `/services/ots`,
              }}
            >
              <ConfirmPrompt
                when={
                  dirty &&
                  !isSubmitting &&
                  !isLoadingService &&
                  !isUpdatingOneTimeService &&
                  !isHandlingEstimateNavigation &&
                  !isHandlingScheduler
                }
                message={defaultUnsavedChangesMessage}
              />
              {children}
            </Page>
          );
        }}
      </Formik>
    </>
  );
};
