import { Box, Button, ButtonGroup } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Loader } from '../loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

export const UploadedImage = ({
  source,
  isLoading,
  showZoom = true,
}: {
  source: string;
  isLoading?: boolean;
  showZoom?: boolean;
}) => {
  const classes = useStyles();

  return (
    <TransformWrapper initialScale={1}>
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <>
          {showZoom && (
            <ButtonGroup>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                onClick={() => zoomIn()}
                style={{
                  marginRight: '5px',
                }}
              >
                Zoom In
              </Button>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<FontAwesomeIcon icon={faMinusCircle} />}
                onClick={() => zoomOut()}
                style={{
                  marginRight: '5px',
                }}
              >
                Zoom Out
              </Button>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<FontAwesomeIcon icon={faRefresh} />}
                onClick={() => resetTransform()}
                style={{
                  marginRight: '5px',
                }}
              >
                Reset
              </Button>
            </ButtonGroup>
          )}
          <Box display="flex" mt={2} justifyContent="center" className={classes.imgBox}>
            {isLoading && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Loader type="overlay" />
              </Box>
            )}
            {source && (
              <div
                style={{
                  overflow: 'hidden',
                  width: '100%',
                  height: `100%`,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <TransformComponent>
                  <img
                    src={source}
                    alt={'snap'}
                    style={{
                      width: `100%`,
                      height: `100%`,
                      objectFit: `cover`,
                    }}
                  />
                </TransformComponent>
              </div>
            )}
          </Box>
        </>
      )}
    </TransformWrapper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  imgBox: {
    maxWidth: '100%',
    justifyItems: 'center',
  },
}));
